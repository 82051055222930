<template>
  <div class="flex gap-3 md:flex-row flex-col items-start">
    <ui-file-preview
      class="max-w-32 object-cover"
      :class="isMedia ? 'max-w-48' : 'max-w-32'"
      :src="source.url"
      :title="source.name"
      clickable
      @open="$emit('openPreview')"
    />
    <common-room-chat-message-attachment-preview-action
      v-if="!isMedia"
      v-bind="props"
    />
  </div>
</template>

<script setup lang="ts">
  type Props = {
    source: MessageAttachment;
    isAuthor: boolean;
  };
  defineEmits(['openPreview']);
  const props = defineProps<Props>();

  const isMedia = computed(() => useIncludes(['img', 'video'], getFileTypeByName(props.source.name)));
</script>
