<template>
  <div
    ref="dropWrap"
    class="border border-card h-full flex flex-col relative"
    :class="{ 'rounded-[25px]': !onlyChat }"
  >
    <common-room-chat-header
      v-if="!onlyChat"
      class="shrink-0"
    />
    <div
      ref="wrapMessages"
      class="max-h-full min-h-32 flex-1 flex relative flex-col justify-end"
    >
      <div
        v-if="showHistoryAlert"
        class="absolute top-0 text-center w-full p-2.5 bg-card-placeholder z-10"
      >
        <ui-typography class="md:p-5 p-3 border bg-card-notify-active border-card-notify-border rounded-xl">
          {{ $t('Only you see the full message history') }}.
          <template v-if="roomStore.userPermission.UpdateRoomDetails">
            {{ $t('To show the story to everyone, go to') }}
            <ui-button
              text="settings"
              variant="link"
              underline="base"
              class="font-normal"
              hide-space
              :to="{
                name: 'dashboard-rooms-roomId-edit',
                params: { roomId: roomStore.roomId }
              }"
            />
          </template>
          <template v-else>
            {{ $t('To share the story with everyone, contact the owner') }}
          </template>
          .
        </ui-typography>
      </div>
      <div
        v-show="showList"
        :ref="chatStore.setScrollerRef"
        class="md:py-8 py-4 overflow-auto scrollbar-no-hidde scrollbar-no-rounded w-full relative"
        :class="{ '!pt-20': showHistoryAlert }"
        @scroll="onScrollMessage"
      >
        <ui-button
          v-if="chatStore.hasPrev"
          variant="secondary-outline"
          text="load previous message"
          size="none"
          class="p-2 mx-auto flex aria-disabled:bg-transparent aria-disabled:border-transparent"
          :loading="chatStore.loading.prev"
          @click="chatStore.onLoadMessage('prev')"
        />
        <div
          v-for="(messages, date) in chatStore.groupByMessages"
          :key="date"
          class="flex flex-col"
        >
          <ui-typography
            variant="small"
            :class="[{ 'sticky -top-3': showDate, '!top-2': showHistoryAlert }]"
            class="py-1.5 px-4 rounded-xl bg-card/90 border-primary border z-[1] w-max mx-auto my-2 transition-all"
          >
            {{ date }}
          </ui-typography>
          <common-room-chat-message
            v-for="(message, idx) in messages"
            :key="message.id"
            :small="onlyChat"
            active
            :hide-name="messages[idx - 1]?.author_id === message.author_id"
            :source="message"
            @open-menu="openMenuMessage"
            @open-media-preview="onOpenMediaPreview"
          />
        </div>
        <ui-button
          v-if="chatStore.hasNext"
          text="load next message"
          variant="secondary-outline"
          size="none"
          class="p-2 mx-auto flex aria-disabled:bg-transparent aria-disabled:border-transparent"
          :loading="!chatStore.loading.next"
          @click="chatStore.onLoadMessage('next')"
        />
      </div>
      <ui-button
        v-show="chatStore.showScrollToBottom"
        icon-right="arrow_downward"
        size="lg-icon"
        rounded="full"
        class="absolute bottom-5 right-2.5 z-10"
        @click="chatStore.onScrollToBottom()"
      />
      <div
        v-if="!showList"
        class="w-full overflow-auto flex flex-col justify-center flex-1 md:p-8 p-4"
      >
        <ui-loader
          static
          :error="error"
          hide-overlay
          :show-not-found="!chatStore.messages.length"
          :loading="pending || chatStore.loading.global"
        >
          <template #content-empty>
            <div class="w-36 h-36 m-auto bg-card rounded-xl flex flex-col justify-center items-center">
              <ui-icon
                name="sms"
                class="text-primary text-2xl"
              />
              <ui-typography
                text="Say Hi!"
                variant="body2"
              />
            </div>
          </template>
        </ui-loader>
      </div>
    </div>
    <common-room-chat-footer
      class="shrink-0 mt-auto"
      :class="{ 'rounded rounded-t-none': !onlyChat }"
    />
    <common-room-chat-upload-attach-drop-zone v-if="isDrop" />
  </div>
</template>

<script setup lang="ts">
  import { CommonRoomChatMessageActions } from '#components';
  // Todo: added virtual scroll
  type Props = {
    onlyChat?: boolean;
    isShown?: boolean;
  };
  const props = defineProps<Props>();
  const { routerReplace } = useRouterLocale();
  const roomStore = useRoomStore();

  const chatStore = useChatStore();
  const route = useRoute();
  const nuxtApp = useNuxtApp();
  const wrapMessages = ref<HTMLElement>();
  const showDate = ref(true);
  const { onCreateToast } = useCreateCustomToast(CommonRoomChatMessageActions, {
    onHide: () => {
      chatStore.setActiveMenuMessage(0);
    }
  });
  const { open: openMediaPreview } = useChatAttachmentPreviewModal({});

  const onOpenMediaPreview = (attachment: MessageAttachment) => {
    openMediaPreview({
      attachment
    });
  };
  const openMenuMessage = (
    el: HTMLElement,
    props: {
      messageId: number;
      isAuthor: boolean;
    }
  ) => {
    chatStore.resetSelect();
    onCreateToast(el, props);
  };

  const { pending, error } = useLazyAsyncData(
    'chat-messages',
    async () => {
      const messageToId = route.query.to || roomStore.room?.unread_message_id;
      if (messageToId) {
        await chatStore.goToMessage(Number(messageToId));
        return true;
      }
      await chatStore.initMessages();
      return true;
    },
    {
      server: false
    }
  );

  const { dropWrap, isDrop } = useValidateFile(
    computed(() => ({ multiple: true, resetOnSelect: true, attachmentFilesCount: chatStore.attachments.length })),
    {
      onSelect(files) {
        chatStore.onSelectAttachmentWithModal(files as File[], {
          initMessage: chatStore.coreFormRef?.values?.message
        });
      }
    }
  );

  const showList = computed(
    () => !(error.value || pending.value || chatStore.loading.global || !chatStore.messages.length)
  );

  const showHistoryAlert = computed(
    () => !roomStore.isVisibleHistory && (roomStore.userStatus.admin || roomStore.userStatus.owner)
  );

  const { start: onHideDate } = useTimeoutFn(() => {
    showDate.value = false;
  }, 5000);

  const onScrollMessage = async () => {
    showDate.value = true;
    await chatStore.onScrollMessages();
    onHideDate();
  };

  watch(wrapMessages, wrap => {
    if (wrap) {
      chatStore.setMinScrollSizeLoad(wrap.getBoundingClientRect().height + 100);
    }
  });

  if (!props.onlyChat) {
    onScopeDispose(nuxtApp.hook('page:transition:finish', chatStore.onInitScroll));
    onScopeDispose(
      nuxtApp.hook('page:loading:end', () => {
        nextTick(chatStore.onInitScroll);
      })
    );
  }
  onKeyStroke('Escape', () => {
    chatStore.resetSelect();
  });

  subscribeRoomNotificationChannel();

  onBeforeMount(() => {
    roomStore.setOnlineCount(roomStore.roomId);
  });
  onBeforeUnmount(() => {
    chatStore.reset();
    roomStore.setOnlineCount(roomStore.roomId, true);
  });

  onMounted(() => {
    chatStore.checkShowScrollToBottom();
    if (route.query.to) {
      routerReplace({
        ...route,
        query: {
          ...route.query,
          to: undefined
        }
      });
    }
  });
</script>
