<template>
  <div
    v-if="content"
    ref="wrapRef"
    v-dompurify-html="content"
  />
</template>

<script setup lang="ts">
  type Props = {
    content?: string;
  };
  const props = defineProps<Props>();
  const wrapRef = ref<HTMLElement>();
  const checkInternalLink = useCheckInternalLink();
  const { routerPush } = useRouterLocale();

  const hydrationHtml = () => {
    if (wrapRef.value) {
      const anchors = wrapRef.value.getElementsByTagName('a');
      useEach(anchors, linkEl => {
        const internalLink = checkInternalLink(linkEl.getAttribute('href'), linkEl.getAttribute('target'));
        if (internalLink) {
          const handler = (event: Event) => {
            event.preventDefault();
            routerPush(internalLink as any);
          };
          linkEl.addEventListener('click', handler);
        }
      });
    }
  };

  watch(
    wrapRef,
    () => {
      nextTick(hydrationHtml);
    },
    {
      immediate: true
    }
  );

  watch(
    () => props.content,
    () => {
      nextTick(hydrationHtml);
    }
  );
</script>
