<template>
  <div
    :id="`message-${source.id}`"
    ref="messageRef"
    class="py-1.5 w-full relative md:px-8 px-4 flex items-center outline-none default-transition"
    :class="{
      'ml-auto justify-end': isAuthor,
      'flex-row-reverse': !isAuthor,
      'bg-gray': isActiveMessage,
      'cursor-pointer': chatStore.activeSelect,
      'select-none': hasTouch
    }"
    tabindex="0"
    @click="onClickMessage"
    @keydown.enter="onClickMessage"
    @contextmenu.prevent="onOpenMenu"
  >
    <ui-button
      v-if="chatStore.activeSelect"
      size="xs-icon"
      rounded="full"
      icon-left="check"
      class="shrink-0"
      :class="isAuthor ? 'mr-3' : 'ml-3'"
      variant="secondary"
      :active="isSelected"
    />
    <div
      class="flex gap-2.5"
      :class="[isAuthor ? 'ml-auto' : 'mr-auto', small ? 'max-w-full' : 'md:max-w-[80%] max-w-[90%]']"
    >
      <ui-avatar
        v-if="!isAuthor"
        :user-name="authorName"
        :size="small ? 'xs' : 'sm'"
        class="shrink-0 cursor-pointer"
        :src="source.author?.avatar_url"
        @click="goToProfile"
      />
      <div
        class="message-content md:px-4 px-2.5 rounded-sm"
        :class="[
          isAuthor ? 'rounded-tr-none' : 'rounded-tl-none',
          {
            'max-w-72': showAttachment || isGif,
            'bg-card md:py-2.5 py-1.5 min-w-32': !onlyEmojis
          }
        ]"
        :style="wrapStyle"
      >
        <ui-typography variant="body2">
          <ui-button
            v-if="!isAuthor && !hideName"
            variant="secondary-link"
            hide-space
            @click="goToProfile"
          >
            {{ authorName }}
          </ui-button>
        </ui-typography>
        <div
          v-if="relatedMessage"
          class="bg-card-placeholder border-l border-input-border p-1.5 mb-1.5 cursor-pointer items-center"
          tabindex="0"
          :class="{ 'grid-cols-[2rem_calc(100%-2.25rem)] grid gap-1': relatedMessage.gif }"
          @click.stop="goToReplay"
          @keydown.enter.stop="goToReplay"
        >
          <div v-if="relatedMessage.gif">
            <img
              class="w-8 h-8 object-cover"
              :src="relatedMessage.gif"
              alt="gif"
            />
          </div>
          <div>
            <ui-typography class="text-primary text-ellipsis whitespace-nowrap overflow-hidden">
              {{ relatedUser }}
            </ui-typography>
            <ui-typography
              v-if="relatedMessage.content"
              variant="caption"
              class="text-ellipsis whitespace-nowrap overflow-hidden"
            >
              {{ relatedMessage.content }}
            </ui-typography>
          </div>
        </div>
        <div
          v-if="source.tenor_url"
          class="max-w-full mb-2"
        >
          <img
            :src="source.tenor_url"
            alt="gif"
            @load="onLoadGif"
          />
        </div>
        <div
          v-if="showAttachmentBuffer || showAttachment"
          class="flex flex-col gap-3 md:mt-0 mb-2"
        >
          <template v-if="showAttachmentBuffer">
            <common-room-chat-message-attachment-buffer
              v-for="attachment in chatStore.messagesAttachments[props.source.id]"
              :key="attachment.uid"
              :message-id="source.id"
              :source="attachment"
            />
          </template>
          <template v-if="showAttachment">
            <common-room-chat-message-attachment-preview
              v-for="attachment in source.attachments"
              :key="attachment.id"
              :is-author="isAuthor"
              :source="attachment"
              @open-preview="onOpenMediaPreview(attachment)"
            />
          </template>
        </div>
        <ui-render-html
          v-if="source.message"
          :content="source.message"
          class="break-words text-body-text js-message-content__body"
          :class="{
            'message-content__body--emojis': onlyEmojis,
            'message-content__body--small': small
          }"
        />
        <ui-typography
          :class="onlyEmojis ? 'text-body-text' : 'text-gray-300'"
          variant="small"
          class="text-right mt-0.5"
        >
          <ui-icon
            v-if="isEdit"
            name="edit"
          />
          {{ timestamp }}
        </ui-typography>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { TypedRouteLocationRaw } from '@typed-router';

  type Props = {
    source: Message;
    active?: boolean;
    hideName?: boolean;
    small?: boolean;
  };
  type Emits = {
    (
      e: 'openMenu',
      target: HTMLElement,
      props: {
        messageId: number;
        isAuthor: boolean;
      }
    ): void;
    (e: 'openMediaPreview', val: MessageAttachment): void;
  };
  const props = defineProps<Props>();
  const emits = defineEmits<Emits>();
  const messageRef = ref<HTMLElement>();
  const hasTouch = 'ontouchstart' in window;
  const chatStore = useChatStore();
  const { user } = useAuthUser();
  const isAuthor = computed(() => props.source.author_id === user.value?.id);
  const authorName = useUserFullName(props, 'source.author');
  const { formatTimestamp } = useFormatDate();
  const { routerPush } = useRouterLocale();
  const wrapStyle = ref();

  const relatedMessage = computed(() => {
    if (props.source.related) {
      return getMessagePreviewMeta(props.source.related);
    }
    return null;
  });
  const relatedUser = useUserFullName(props, 'source.related.author');
  const isSelected = computed(() => chatStore.activeSelect && chatStore.selectMessages.includes(props.source.id));
  const isEdit = computed(() => props.source.created_at !== props.source.updated_at);

  const isActiveShow = computed(() => props.active && chatStore.activeToMessage === props.source.id);
  const isActiveMessage = computed(
    () => chatStore.activeMenuMessageId === props.source.id || isSelected.value || isActiveShow.value
  );
  const timestamp = computed(() => formatTimestamp(isEdit.value ? props.source.updated_at : props.source.created_at));
  const showAttachmentBuffer = computed(() => !!chatStore.messagesAttachments[props.source.id]?.length);
  const showAttachment = computed(() => !!props.source.attachments?.length);
  const isGif = computed(() => !!props.source.tenor_url?.length);
  const onlyEmojis = computed(
    () => !isGif.value && !showAttachment.value && !relatedMessage.value && isOnlyEmojis(props.source.message)
  );
  const onClickMessage = () => {
    if (chatStore.activeSelect) {
      chatStore.onSelectMessages(props.source.id);
    }
  };

  const goToReplay = () => {
    if (props.source.related_id) {
      chatStore.goToMessage(props.source.related_id);
    }
  };

  const onOpenMenu = (event: MouseEvent | TouchEvent) => {
    chatStore.setActiveMenuMessage(props.source.id);
    emits('openMenu', createVirtualElWithPoint(event) as HTMLElement, {
      messageId: props.source.id,
      isAuthor: isAuthor.value
    });
  };
  const onOpenMediaPreview = (attachment: MessageAttachment) => {
    emits('openMediaPreview', attachment);
  };
  const routePath = computed(() => ({
    name: 'profile-username',
    params: {
      username: props.source.author.username
    }
  }));
  const goToProfile = () => {
    routerPush(routePath.value as TypedRouteLocationRaw);
  };

  const { start } = useTimeoutFn(
    () => {
      chatStore.setActiveToMessageId(0);
    },
    1500,
    {
      immediate: false
    }
  );

  const onLoadGif = (e: { target: HTMLImageElement }) => {
    const width = e.target.naturalWidth;
    const spaceWrapWidth = 32;
    wrapStyle.value = {
      maxWidth: width + spaceWrapWidth + 'px'
    };
  };

  if (hasTouch) {
    onLongPress(messageRef, onOpenMenu, {
      delay: 500,
      modifiers: {
        prevent: true
      }
    });
  }

  watch(
    isActiveShow,
    val => {
      if (val) {
        start();
      }
    },
    {
      immediate: true
    }
  );
</script>

<style lang="scss">
  .message-content__body {
    * {
      word-break: break-word;
    }
    &--small.message-content__body--emojis * {
      @apply text-3xl;
    }
    &--emojis * {
      @apply md:text-6xl text-3xl;
    }
  }
</style>
